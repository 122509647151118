import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TacticalImg from "../components/tacticalImg"
import AffPes from "../components/pes2021cta"

const legends = () => {
  return (
    <Layout>
      <SEO
        title="Legends available in PES 2021"
        description="Find out how legends work in PES 2021 myClub and the complete list of iconic players Konami has in store for you."
      />
      <div className="container">
        <h1>Legends available in PES 2021</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Legends</li>
        </ul>
        <p>
          Konami releases a different list of iconic players to play in myClub
          every year.
        </p>
        <p>
          Legends are some of the most exciting players available in the game,
          but they work a bit differently from other players in PES 2021.
        </p>
        <p>
          You get legends by spinning agents. Very rarely will you get the
          chance to scout one - only if you earn the "Specific Players" scout
          given by Konami after you win a specific weekly competition (usually
          by beating the AI in "Legend" difficulty).
        </p>
        <p>
          In previous editions, Legends would be available for 1 full week, and
          you could get them with GP, by spinning those weekly Agents featuring
          300 or 450 players (25.000 GP for each spin, rewarding you with 3
          different players and gradually emptying the box).
        </p>
        <p>
          That changed in PES 2020. That year, you could only spin these agents
          with coins, from Thursday to Sunday of each week.
        </p>
        <p>
          Each spin costs 100 coins. You have unlimited spins and the chance of
          getting duplicates.
        </p>
        <p>
          Very rarely have I seen otherwise this year, but if that's the case,
          you will usually receive a notification from Konami.
        </p>
        <p>
          The list of available legends to get is different every week. Most
          weeks have a theme around it (such as Spanish stars, or Serie A
          players), so you will usually find legends related to that league as
          well.
        </p>
        <p>Here are some other things you should consider:</p>
        <ul>
          <li>You can't trade players for Legends.</li>
          <li>
            Legends are available in Agents, but never all at the same time.
          </li>
          <li>
            Legends have their unique animation before presenting the player.
            Iconic Moments have a different animation in PES 2021.
          </li>
          <li>
            Once you unlock these players in myClub, you can use them in Master
            League.
          </li>
        </ul>
      </div>
      <AffPes />
      <div className="container">
        <h3>Legends and Condition Rating</h3>
        <p>
          Legends had a{" "}
          <Link to="/player-form-condition-rating/">Condition Rating</Link> of
          "C" every week. Since these players are no longer in active
          competition, their Condition Rating stayed the same after every weekly
          Live Update.
        </p>
        <p>
          When PES 2019 was launched, "Players of the Week" became more and more
          popular and some overpowered beasts emerged. A lot of those players
          had good games in real life frequently, which made Legends less and
          less useful as the weeks in myClub progressed.
        </p>
        <p>
          Thankfully, Konami noticed this and created a bigger incentive to use
          Legends throughout the season. As of February 13, 2020, every Legend
          has a Condition Rating of "B" instead of "C" in PES 2020.
        </p>
        <TacticalImg
          src={`legends-b-condition.jpg`}
          alt={`Condition Ratings update for Legends in PES 2020`}
        />
        <h3>Iconic Moment Series</h3>
        <p>
          When PES 2020 Data Pack 5.0 was launched (version 4.3.0 on PES Mobile
          for iOS/Android), Konami introduced a new kind of Legends: Iconic
          Moment Series.
        </p>
        <p>
          These cards have unique designs, based on photos taken during a
          standout match in their career. These players come with boosted stats
          that reflect their performance on that specific game.
        </p>
        <p>
          You can boost these players' stats even further if you match their
          affiliated team to your own - so if you're using Puyol, you should
          change to the Barcelona Kit if you want to improve his stats.
        </p>
        <p>
          A special version of Maradona was given for free to celebrate the
          launch of the Series. To get the other players, you will need to try
          your luck with coins, whenever you see they are available.
        </p>
        <p>
          Konami later announced there will be multiple variations of the same
          Iconic Moment Series player, each one with a unique photo so that we
          can easily tell the difference between them.
        </p>
        <p>
          If you get a duplicate Iconic Moment Series player and decide to
          convert him into an EXP Trainer, you should use him to train the
          identical variation of that player, as Konami promises doing so will
          "yeld a tremendous effect". It will also have an enhanced effect if
          used on different variations of that same player.
        </p>
        <p>
          This was the initial lineup of legends belonging to Iconic Moment
          Series in PES 2020:
        </p>
        <ul className="grid-list">
          <li>Giovane Élber</li>
          <li>Oliver Kahn</li>
          <li>Karl-Heinz Rummenigge</li>
          <li>Xavi</li>
          <li>Diego Maradona</li>
          <li>Shunsuke Nakamura</li>
          <li>Carles Puyol</li>
          <li>Frank Rijkaard</li>
          <li>Marco van Basten</li>
          <li>Fernando Morientes</li>
          <li>Andrés Iniesta</li>
          <li>Iker Casillas</li>
          <li>Xabi Alonso</li>
          <li>Diego Forlán</li>
          <li>Fernando Torres</li>
        </ul>
        <p>
          As every other legend, they will present a weekly Condition Rating of
          "B" by default.
        </p>
        <h4>Iconic Moments in PES 2021 (Veteran Bonus)</h4>
        <p>
          If you played myClub mode in either PES 2020 or PES 2020 LITE, you
          will be eligible to receive the Veterans Bonus if you purchase the
          Season Update between 15/09/2020 and 10/12/2020. You will need to
          claim it with the same account that you used to play the 2020 game.
        </p>
        <p>
          This Bonus will offer you some legends from the get go, depending on
          how many Legends you owned in the previous year.
        </p>
        <p>Regarding Legends, Konami announced the following tiers:</p>
        <ul>
          <li>
            If you own 1 to 3 Legends, you will get 1x 'PES 2020-21 Iconic
            Moment Series' Special Agent.
          </li>
          <li>
            If you own 4 to 6 Legends: 2x 'PES 2020-21 Iconic Moment Series'
            Special Agent.
          </li>
          <li>
            If you own 7 to 9 Legends: 3x 'PES 2020-21 Iconic Moment Series'
            Special Agent.
          </li>
          <li>
            If you own 10 to 12 Legends: 4x 'PES 2020-21 Iconic Moment Series'
            Special Agent.
          </li>
          <li>
            If you own 13 or more Legends: 5x 'PES 2020-21 Iconic Moment Series'
            Special Agent.
          </li>
        </ul>
        <p>
          This is the list of Iconic Moment Legends available, along with the
          match date:
        </p>
        <ul className="grid-list">
          <li>
            A. Del Piero <small>(22.05.1996)</small>
          </li>
          <li>
            D. Beckham <small>(26.05.1999)</small>
          </li>
          <li>
            D. Bergkamp <small>(02.03.2002)</small>
          </li>
          <li>
            F. Beckenbauer <small>(14.04.1976)</small>
          </li>
          <li>
            F. Inzaghi <small>(11.12.2002)</small>
          </li>
          <li>
            F. Rijkaard <small>(24.05.1989)</small>
          </li>
          <li>
            Fernando Torres <small>(05.02.2006)</small>
          </li>
          <li>
            H. Nakata <small>(06.05.2001)</small>
          </li>
          <li>
            Iniesta <small>(06.05.2009)</small>
          </li>
          <li>
            K. Rummenigge <small>(13.06.1981)</small>
          </li>
          <li>
            M. Van Basten <small>(24.05.1989)</small>
          </li>
          <li>
            P. Nedvěd <small>(14.05.2003)</small>
          </li>
          <li>
            P. Vieira <small>(08.04.2001)</small>
          </li>
          <li>
            Puyol <small>(17.05.2006)</small>
          </li>
          <li>
            R. Carlos <small>(15.05.2002)</small>
          </li>
          <li>
            R. Gullit <small>(24.05.1989)</small>
          </li>
        </ul>
        <h2>Legends in PES 2021 - The Complete List</h2>
        <p>
          Apart from the Iconic Moment Legends listed above, Konami had a Legend
          Challenge 2 weeks after PES 2021 - Season Update was launched, where
          you could get one of these players:
        </p>
        <TacticalImg
          src={`pes2021-legends.jpg`}
          alt={`List of Legends in PES 2021`}
        />
        <p>
          More legends should be made available as the season progresses. The
          final list should be similar to the one in the previous year, which
          you can have a look below.
        </p>
        <p>For the time being, this is it!</p>
        <ul className="grid-list">
          <li>A. Arshavin (LWF)</li>
          <li>A. Del Piero (SS)</li>
          <li>A. Pirlo (DMF)</li>
          <li>Bebeto (SS)</li>
          <li>Cafu (RB)</li>
          <li>Casillas (GK)</li>
          <li>D. Beckham (RMF)</li>
          <li>D. Beckham (RMF)</li>
          <li>D. Bergkamp (SS)</li>
          <li>D. Law (CF)</li>
          <li>D. Maradona (SS)</li>
          <li>Deco (AMF)</li>
          <li>F. Beckenbauer (CB)</li>
          <li>F. Lampard (CMF)</li>
          <li>F. Rijkaard (DMF)</li>
          <li>F. Totti (SS)</li>
          <li>Fernando Torres (CF)</li>
          <li>G. Batistuta (CF)</li>
          <li>H. Nakata (AMF)</li>
          <li>Iniesta (CMF)</li>
          <li>J. Cruijff (SS)</li>
          <li>K. Rummenigge (SS)</li>
          <li>L. Figo (RWF)</li>
          <li>L. Matthäus (CMF)</li>
          <li>M. Owen (CF)</li>
          <li>M. van Basten (CF)</li>
          <li>Morientes (CF)</li>
          <li>O. Kahn (GK)</li>
          <li>P. Kluivert (CF)</li>
          <li>P. Maldini (CB)</li>
          <li>P. Nedvěd (LMF)</li>
          <li>P. Scholes (CMF)</li>
          <li>P. Vieira (DMF)</li>
          <li>P. Čech (GK)</li>
          <li>Park Ji Sung (LMF)</li>
          <li>Puyol (CB)</li>
          <li>R. Carlos (LB)</li>
          <li>R. Gullit (AMF)</li>
          <li>R. Pirès (LMF)</li>
          <li>R. Van der Vaart (AMF)</li>
          <li>Rivaldo (AMF)</li>
          <li>Romário (CF)</li>
          <li>Ronaldinho G. (AMF)</li>
          <li>S. Campbell (CB)</li>
          <li>S. Ono (AMF)</li>
          <li>Xavi (CMF)</li>
          <li>Zico (AMF)</li>
        </ul>
        <h2>Legends in PES 2020 - The Complete List</h2>
        <p>
          Here is the complete list of iconic players Konami made available in
          PES 2020. There are 84 names in total - most should be familiar to
          football fans.
        </p>
        <p>
          David Beckham is listed twice below. That is not a typo - there are 2
          versions of this player in the game. Same for Ronaldinho, who was the
          poster boy for this year. Players who pre-ordered the game got
          Ronaldinho as a pre-order bonus.
        </p>
        <p>
          <small>
            New: a player that has been added to the game in Data Pack 4.0
            (February 2020)
          </small>
        </p>
        <ul className="grid-list">
          <li>A. Arshavin (LWF)</li>
          <li>A. Cole (CF)</li>
          <li>A. Del Piero (SS)</li>
          <li>
            A. Pirlo (DMF) <small>New</small>
          </li>
          <li>Á. Recoba (SS)</li>
          <li>Adriano (CF)</li>
          <li>B. Lizarazu (LB)</li>
          <li>
            B. Robson (CMF) <small>New</small>
          </li>
          <li>Bebeto (SS)</li>
          <li>C. Abbiati (GK)</li>
          <li>
            C. Chivu (CB) <small>New</small>
          </li>
          <li>Cafu (RB)</li>
          <li>D. Beckham (RMF)</li>
          <li>D. Beckham (RMF)</li>
          <li>D. Bergkamp (SS)</li>
          <li>
            D. Law (CF) <small>New</small>
          </li>
          <li>D. Lugano (CB)</li>
          <li>D. Maradona (SS) </li>
          <li>D. Massaro (CF)</li>
          <li>D. Stanković (CMF)</li>
          <li>D. Yorke (CF)</li>
          <li>Deco (AMF)</li>
          <li>Denílson (LWF)</li>
          <li>Dida (GK)</li>
          <li>E. Cambiasso (DMF)</li>
          <li>E. Petit (DMF)</li>
          <li>
            Élber (CF) <small>New</small>
          </li>
          <li>F. Baresi (CB)</li>
          <li>F. Beckenbauer (CB)</li>
          <li>
            F. Inzaghi (CF) <small>New</small>
          </li>
          <li>
            F. Lampard (CMF) <small>New</small>
          </li>
          <li>F. Ljungberg (RMF)</li>
          <li>
            F. Rijkaard (DMF) <small>New</small>
          </li>
          <li>F. Toldo (GK)</li>
          <li>F. Totti (SS)</li>
          <li>
            G. Barry (DMF) <small>New</small>
          </li>
          <li>G. Batistuta (CF)</li>
          <li>
            G. Bergomi (CB) <small>New</small>
          </li>
          <li>Gilberto Silva (DMF)</li>
          <li>
            Guardiola (DMF) <small>New</small>
          </li>
          <li>
            Guti (CMF) <small>New</small>
          </li>
          <li>H. Nakata (AMF)</li>
          <li>
            I. Córdoba (CB) <small>New</small>
          </li>
          <li>I. Zamorano (CF)</li>
          <li>Iniesta (CMF)</li>
          <li>
            Irwin (LB) <small>New</small>
          </li>
          <li>J. Cruijff (SS) </li>
          <li>
            J. Koller (CF) <small>New</small>
          </li>
          <li>J. Zanetti (RB)</li>
          <li>K. Rummenigge (SS)</li>
          <li>L. Figo (RWF)</li>
          <li>L. Giuly (RWF)</li>
          <li>L. Matthäus (CMF)</li>
          <li>
            M. Owen (CF) <small>New</small>
          </li>
          <li>M. Salas (CF )</li>
          <li>
            M. van Basten (CF) <small>New</small>
          </li>
          <li>
            Morientes (CF) <small>New</small>
          </li>
          <li>O. Kahn (GK)</li>
          <li>
            P. Čech (GK) <small>New</small>
          </li>
          <li>P. Kluivert (CF)</li>
          <li>P. Maldini (CB)</li>
          <li>P. Nedvěd (LMF)</li>
          <li>P. Scholes (CMF)</li>
          <li>P. Vieira (DMF)</li>
          <li>Park Ji Sung (LMF)</li>
          <li>
            Puyol (CB) <small>New</small>
          </li>
          <li>R. Carlos (LB)</li>
          <li>
            R. Giggs (LMF) <small>New</small>
          </li>
          <li>R. Gullit (AMF)</li>
          <li>
            R. Keane (CF) <small>New</small>
          </li>
          <li>R. Pirès (LMF)</li>
          <li>R. Rocha (CB)</li>
          <li>
            R. Van der Vaart (AMF) <small>New</small>
          </li>
          <li>
            Rái (AMF) <small>New</small>
          </li>
          <li>Rivaldo (AMF)</li>
          <li>Romário (CF)</li>
          <li>Ronaldinho G. (AMF)</li>
          <li>Ronaldinho G. (AMF)</li>
          <li>S. Campbell (CB)</li>
          <li>
            S. Given (GK) <small>New</small>
          </li>
          <li>
            S. Nakamura (AMF) <small>New</small>
          </li>
          <li>W. Samuel (CB)</li>
          <li>
            Xavi (CMF) <small>New</small>
          </li>
          <li>Y. Djorkaeff (AMF)</li>
          <li>Zico (AMF)</li>
        </ul>
      </div>
    </Layout>
  )
}

export default legends
